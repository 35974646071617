import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useUser } from '../useUser';
import { useWhere } from '../useWhere';
import { getTimeParams } from '../../util/Restaurant';
import { getPhones } from '../../redux/legacy/reducers/ducks/legacy/ProfileDuck';
import * as orderActions from '../../redux/legacy/reducers/ducks/legacy/OrderDuck';
import { setSelectedAddress as storeSelectedAddress } from '../../redux/legacy/reducers/ducks/new/Address/AddressReducer';
import { formatCartForApi } from '../../util/Helpers';

function useSetCart(needToStart) {
  const dispatch = useDispatch();
  const { userData } = useUser();

  const [onStartAlready, setOnStartAlready] = useState(false);
  const [onSetCart, setOnSetCart] = useState(false);
  const [checkoutStarted, setCheckoutStarted] = useState(false);
  const [allReadyForSetCart, setAllReadyForSetCart] = useState(false);
  const [addressIsFine, setAddressIsFine] = useState(false);
  const [phoneIsFine, setPhoneIsFine] = useState(false);
  const [onAddressCreation, setOnAddressCreation] = useState(false);
  const { dAddress } = useWhere();

  const {
    cartItems,
    selectedRestaurant,
    type,
    time,
    setCartResponse,
    addressResponse,
    address,
    phones,
    getAddressesResponse,
  } = useSelector(
    ({
      order,
      restaurant,
      profile,
      address: selectedAddress,
      time: selectedTime,
    }) => ({
      cartItems: order.cartItems,
      selectedRestaurant: restaurant.selectedRestaurantId,
      type: order?.type,
      time: selectedTime.selectedTime,
      addressResponse: order?.addressResponse,
      setCartResponse: order.setCartResponse,
      address: selectedAddress.selectedAddress,
      phones: profile.phones,
      getAddressesResponse: profile.getAddressesResponse,
    })
  );

  function setCart() {
    const newCartData = formatCartForApi(cartItems);

    const apiData = {
      client: selectedRestaurant,
      data: {
        mode: type.value,
        ...getTimeParams(time),
        cart: newCartData,
      },
    };

    if (
      type.value === 'delivery' &&
      addressResponse &&
      addressResponse?.address?.id
    ) {
      apiData.data.address =
        dAddress?.address_id || addressResponse?.address?.id;
    }

    if (type.value === 'delivery' && address && address?.address_id) {
      apiData.data.address = dAddress?.address_id || address?.address_id;
    }

    dispatch(
      orderActions.setCart({
        payloadCartData: { cart: cartItems },
        apiData,
        userProfile: userData,
        isCheckoutStart: true,
      })
    );

    dispatch(
      orderActions.setAddressResponse({
        ...addressResponse,
        orderCase: false,
      })
    );

    setOnSetCart(true);
  }

  useEffect(() => {
    if (onSetCart && setCartResponse) {
      // TODO: Add code to check if set cart failed
      if (setCartResponse?.error) setCheckoutStarted(false);
      else setCheckoutStarted(true);
    }
  }, [setCartResponse]);

  useEffect(() => {
    if (
      (address && address?.address_id) ||
      (addressResponse && addressResponse?.address?.id)
    ) {
      setAddressIsFine(true);
    }
  }, [address, addressResponse]);

  useEffect(() => {
    if (userData) {
      if (address && address?.user_id) {
        // Clean only user related fields from the address, if the user has changed
        if (userData.id !== address.user_id) {
          // eslint-disable-next-line no-unused-vars, camelcase
          const { user_id, address_id, primary, updatedAddress } = {
            ...address,
          };

          dispatch(storeSelectedAddress(updatedAddress));
        }
      }
    }
  }, [userData, address]);

  useEffect(() => {
    if (onAddressCreation) {
      if (addressResponse && addressResponse?.address?.id) {
        const updatedAddress = {
          ...address,
          primary: false,
          user_id: userData.id,
          address_id: addressResponse?.address?.id,
          aftercreated: true,
        };

        dispatch(storeSelectedAddress(updatedAddress));
      } else {
        setOnStartAlready(false);
      }
    }
  }, [addressResponse, onAddressCreation]);

  useEffect(() => {
    if ((phoneIsFine && addressIsFine) || address?.aftercreated) {
      setAllReadyForSetCart(true);
    }
  }, [phoneIsFine, addressIsFine, address, addressResponse]);

  useEffect(() => {
    if (addressResponse?.orderCase && allReadyForSetCart) {
      setCart();
    }
    if (allReadyForSetCart && needToStart) {
      setCart();
    }
  }, [
    allReadyForSetCart,
    needToStart,
    addressResponse?.address?.id,
    address?.address_id,
  ]);

  useEffect(() => {
    if (needToStart && !onStartAlready) {
      if (userData) {
        setOnStartAlready(true);

        let goSetCart = true;

        if (
          type.value === 'delivery' &&
          address &&
          !address.address_id &&
          !onAddressCreation
        ) {
          goSetCart = false;
          setOnAddressCreation(true);
          const exists = getAddressesResponse?.response?.find(
            (item) => item.entered_address === address.entered_address
          );
          if (!exists) {
            dispatch(
              orderActions.createNewAddress({
                address,
                userId: userData.id,
                orderCase: true,
              })
            );
          } else {
            dispatch(
              orderActions.setAddressResponse({
                address: { ...exists, userId: userData.id },
                orderCase: false,
              })
            );
          }
        } else {
          setAddressIsFine(true);
        }

        // TODO: we should wait until created just like the address
        if (phones?.length === 0) {
          dispatch(getPhones()); // TODO: To be replaced with create phone
        }

        setPhoneIsFine(true);

        if (goSetCart) {
          setAllReadyForSetCart(true);
        }
      }
    }
  }, [needToStart, address]);

  return [checkoutStarted];
}

export default useSetCart;
