import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useUser } from '../../../hooks/useUser';
import { useWhere } from '../../../hooks/useWhere';

import { toggleAddress } from '../../../redux/legacy/reducers/ducks/legacy/MainDuck';
import { routeNames } from '../../../util/Constants';
import GoogleAutoCompleteInput from '../GoogleAutoCompleteInput';

function OrderAddressInput({ onClose }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoggedIn } = useUser();
  const { setSelectedAddress, dAddress } = useWhere();

  const {
    selectedAddress: currentAddress,
    addresses,
    user,
  } = useSelector(
    ({ order: orderObj, profile, restaurants, main, address }) => ({
      addresses: profile.getAddressesResponse?.response,
      order: orderObj,
      selectedRestaurant: restaurants.selectedRestaurant,
      selectedAddress: address.selectedAddress,
      user: main.user,
    })
  );

  const onRadioChange = (ev) => {
    const addressIdEv = parseInt(ev.target.value, 10);
    const newAddress = addresses?.find((i) => i.id === addressIdEv);

    if (newAddress) {
      // Use the new redux store for address
      const selectedAddress = {
        entered_address: `${newAddress.address}, ${newAddress.city}, ${newAddress.state} ${newAddress.zip}`,
        address: newAddress.address,
        city: newAddress.city,
        state: newAddress.state,
        zipcode: newAddress.zip,
        latitude: newAddress.latitude,
        longitude: newAddress.longitude,
        user_id: user.id,
        address_id: newAddress.id,
        is_primary: newAddress.primary,
        addition: newAddress?.addition,
      };

      setSelectedAddress(selectedAddress);
    }

    onClose();
  };

  return (
    <div data-test-id="order-address-input">
      <GoogleAutoCompleteInput />
      {isLoggedIn && (
        <Box>
          <Stack direction="row" justifyContent="space-between">
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 600 }}
              mt={2}
              mb={2}
            >
              Your Addresses
            </Typography>
            <Button
              data-test-id="RTZBn-Vfs7DsQMMxzO_4f"
              onClick={() => {
                navigate(routeNames.accountDetailsAddresses);
                dispatch(toggleAddress(false));
              }}
            >
              Edit
            </Button>
          </Stack>

          <RadioGroup
            variant="outlined"
            iconcomponent={ArrowDownwardIcon}
            data-test-id="select-on-address-id-Wrapper"
          >
            {addresses?.map((option) => (
              <Box key={option.id}>
                <Divider mb={2} />
                <FormControlLabel
                  key={`order-address-${option.id}`}
                  onChange={onRadioChange}
                  value={`${option.id}`}
                  data-test-id={`select-on-address-id-Wrapper-${option.id}`}
                  control={
                    <Radio
                      checked={
                        (dAddress || currentAddress) &&
                        option.id === (dAddress || currentAddress)?.address_id
                      }
                    />
                  }
                  name={`${option.address}, ${option.city}, ${option.state} ${option.zip}`}
                  label={
                    <Box ml={1} py={1}>
                      <Stack direction="row" spacing={1}>
                        <Typography
                          display="inline"
                          variant="body1"
                          sx={{ fontWeight: 600 }}
                        >
                          {option.address}
                        </Typography>
                        {option.primary && (
                          <Typography
                            variant="body1"
                            color="secondary"
                            sx={{ fontWeight: 600 }}
                          >
                            primary
                          </Typography>
                        )}
                      </Stack>
                      <Typography variant="grayText">
                        {option.city}, {option.state} {option.zip}
                      </Typography>
                    </Box>
                  }
                />
              </Box>
            ))}
          </RadioGroup>
        </Box>
      )}
    </div>
  );
}

OrderAddressInput.propTypes = {
  onClose: PropTypes.func,
};

OrderAddressInput.defaultProps = {
  onClose: () => null,
};

export default OrderAddressInput;
