import { createSlice } from '@reduxjs/toolkit';
import { SelectedAddress } from './Address.types';

export const INITIAL_STATE = {
  selectedAddress: null as SelectedAddress | null,
};

const mainSlice = createSlice({
  name: 'address',
  initialState: { ...INITIAL_STATE },
  reducers: {
    setSelectedAddress(state, action) {
      localStorage.setItem('deliver_address', JSON.stringify(action.payload));
      return {
        ...state,
        selectedAddress: action.payload,
      };
    },
  },
});

export const { setSelectedAddress } = mainSlice.actions;

export default mainSlice.reducer;
